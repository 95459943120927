
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { isRequired } from '@/utils/validation'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { dateDashNotationToDotWithForm, dateDotNotationToDashWithForm } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'

import { useCreateComplianceCheck, useUpdateComplianceCheck } from '@/api/complianceCheck'
import { useGetUsersBasic } from '@/api/user'

import { FormField, FORM_FIELDS_ENUM, DATA_TYPE } from '@/utils/types/formField'
import { isOfType } from '@/utils/types/isOfType'
import { ComplianceCheckInput, ComplianceCheckOutput } from '@/api/types/complianceCheck'

export default defineComponent({
  name: 'AddEditComplianceCheckDialog',
  components: {
    CommonAddEditDialog,
    CommonNumberInput,
    CommonDateInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    complianceCheckToEdit: {
      type: Object as PropType<ComplianceCheckOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.complianceCheckToEdit))

    const { exec: getUsersBasic, data: usersBasic, isLoading: isLoadingUsersBasic } = useGetUsersBasic()
    getUsersBasic()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'complianceCheck',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('persons.profile.tabMenu.compliance.form.complianceCheck') as string),
        ],
      },
      {
        value: 'compliancePoints',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('persons.profile.tabMenu.compliance.form.compliancePoints') as string),
        ],
      },
      {
        value: 'compliancePeriod',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.NUMBER,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('persons.profile.tabMenu.compliance.form.compliancePeriod') as string),
        ],
      },
      {
        value: 'complianceSignedBy',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        isRequired: true,
        items: computed(() => usersBasic.value ?? []),
        isLoading: computed(() => isLoadingUsersBasic.value),
        rules: [
          (value: string) =>
            isRequired(value, root.$t('persons.profile.tabMenu.compliance.form.complianceSignedBy') as string),
        ],
      },
    ]

    const form = ref<ComplianceCheckInput | ComplianceCheckOutput>(
      isEditMode.value ? cloneDeep(props.complianceCheckToEdit) : ({} as ComplianceCheckInput)
    )

    const { createComplianceCheck, isLoading: isLoadingCreateComplianceCheck } = useCreateComplianceCheck()

    async function onAdd(): Promise<void> {
      if (!isOfType<ComplianceCheckOutput>(form.value, 'id')) {
        try {
          let updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)
          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          updatedForm.person = Number(root.$route.params.id)

          await createComplianceCheck(updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateComplianceCheck, isLoading: isLoadingUpdateComplianceCheck } = useUpdateComplianceCheck()

    const RESET_PROPS_FOR_PUT_REQ = ['links', 'createdAt', 'createdBy', 'title', 'updatedAt', 'updatedBy']

    async function onEdit(): Promise<void> {
      if (isOfType<ComplianceCheckOutput>(form.value, ['id'])) {
        try {
          let updatedForm = mapBasicEntityToIdWithForm<ComplianceCheckOutput>(form.value)

          updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)
          updatedForm = convertCommaIntoDotInputWithForm(FORM_FIELDS, updatedForm)

          RESET_PROPS_FOR_PUT_REQ.forEach((entry) => delete updatedForm[entry])

          await updateComplianceCheck(updatedForm.id, updatedForm)

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditComplianceCheck = computed(
      () => isLoadingCreateComplianceCheck.value || isLoadingUpdateComplianceCheck.value
    )

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    watch(
      () => props.complianceCheckToEdit,
      () => {
        if (props.complianceCheckToEdit) {
          form.value = props.complianceCheckToEdit

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)

          form.value = convertDotIntoCommaInputWithForm(FORM_FIELDS, form.value)
        }
      },
      {
        immediate: true,
      }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditComplianceCheck,
      },
      listeners: {
        onAdd,

        onEdit,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
